import { FC } from 'react';
import Tooltips from './bootstrap/Tooltips';
import Icon from './icon/Icon';
import { TIconsSize } from '../type/icons-type';

interface IconProps {
    icon: string | undefined;
    label: string | undefined;
    size?: TIconsSize;
    color?: string;
    className?: string;
}

const IconWithTooltip: FC<IconProps> = ({ icon, label, size = 'lg', color = 'black', className }) => {
    return (
        <Tooltips placement="top" title={label} className='mb-2'>
            <Icon icon={icon} className={className || "ms-2"} size={size} color={color} />
        </Tooltips>
    );
};

export default IconWithTooltip;