import { useCallback, useEffect, useRef, useState } from "react";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import useFetch from "../../../hooks/useFetch";
import { BudgetService } from "../../../services/budgets/budgetService";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../layout/Page/Page";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Spinner from "../../../components/bootstrap/Spinner";
import { BudgetsApiResponse, Concept } from "../../../type/budgets-type";
import { toast } from "react-toastify";
import { StatusService } from "../../../services/status/statusService";
import { Status } from "../../../type/status-type";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { useFormik } from "formik";
import AsyncImg from "../../../components/AsyncImg";
import FormLabel from "../../../components/FormLabel";
import { IconDownload, IconBrandGmail } from '@tabler/icons-react';
import Checks from "../../../components/bootstrap/forms/Checks";
import StatusesDropdown from "../../../components/dropdowns/StatusesDropdown";
import moment from "moment";
import { ClientMenu, FinancesMenu } from "../../../menu";
import StatusesTimeLine from "../components/StatusesTimeLine";
import slugify from "slugify";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import Icon from "../../../components/icon/Icon";
import classNames from "classnames";
import 'react-dropdown/style.css';
import '../../../styles/stylesEdited.scss';

interface IFormExtraInfo {
    budget: string;
    pageText: string;
}

const BudgetView = () => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { userCan } = usePrivilege();
    const [budgetStatuses, setBudgetStatuses] = useState<Status[]>([]);
    const fileInputRef1 = useRef<HTMLInputElement>(null);
    const fileInputRef2 = useRef<HTMLInputElement>(null);
    const budgetService = new BudgetService();

    const [spinner, setSpinner] = useState<boolean>(false);
    const [spinnerMail, setSpinnerMail] = useState<boolean>(false);

    const conceptRef = useRef<HTMLInputElement | null>(null);
    const commentRef = useRef<HTMLTextAreaElement | null>(null);
    const dateRef = useRef<HTMLInputElement | null>(null);

    const [budget, loadingBudget, budgetError, refetchBudget] = useFetch(useCallback(async () => {
        const response = (await budgetService.getBudget(id));
        return response.getResponseData() as BudgetsApiResponse;
    }, []));

    const [statusChanges, loadingChanges, changesError, refetchChanges] = useFetch(useCallback(async () => {
        const response = await budgetService.listStatusChanges(id);
        return response.getResponseData();
    }, []));

    // Obtiene la lista de estados de los presupuestos de la API
    const getStatusList = async (orientedTo: string): Promise<Status[] | undefined> => {
        const response = await (await (new StatusService()).listStatuses({ filter_filters: { oriented_to: orientedTo }, limit: 999999 })).getResponseData();
        if (response.success) {
            return response.data.statuses;
        } else {
            return [];
        }
    };

    // Carga los estados de los presupuestos
    const fetchStatuses = async () => {
        let budgetStatusList = await getStatusList("budget");
        setBudgetStatuses(budgetStatusList !== undefined ? budgetStatusList : []);
    };

    const checkInvoiceData = async (callback: () => void) => {
        try {
            const response = (await (new BudgetService).checkInvoiceData(id)).getResponseData();
            if (response.success) {
                if (response.data.length > 0) {
                    let fieldsMissing = '';
                    response.data.forEach((field: any) => {
                        fieldsMissing += field + ', ';
                    });
                    fieldsMissing = fieldsMissing.slice(0, -2);
                    toast.error('Faltan los siguientes datos del cliente: ' + fieldsMissing);
                } else {
                    callback();
                }
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al validar la factura');
        }
    };

    const downloadInvoice = async (hasInvoice: any, stageId: string, stageIdentifierName: string) => {
        try {
            const response = (await (new BudgetService).downloadInvoice(id, stageId ? stageId : ''));
            if (response && hasInvoice.length > 0) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', stageIdentifierName + '_' + budget?.address + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                if (hasInvoice.length === 0) {
                    toast.success('Factura generada correctamente');
                    refetchBudget();
                } else {
                    toast.error('Error al descargar la factura');
                }
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al descargar la factura');
        }
    };

    const sendMailToClient = async (stage?: string) => {
        const apiCall = stage ? (new BudgetService).sendInvoiceToClient(id, stage) : (new BudgetService).sendBudgetToClient(id);
        try {
            const response = (await apiCall).getResponseData();
            if (response.message && response.message !== 'El cliente no tiene email') {
                setSpinnerMail(false);
                toast.success('Correo enviado correctamente');
                refetchBudget();
            } else {
                setSpinnerMail(false);
                toast.error(response.message || 'Error al enviar el correo');
            }
        } catch (error: any) {
            setSpinnerMail(false);
            toast.error('Error al enviar el correo');
        } finally {
            setSpinner(false);
        }
    };

    const toggleStageValidate = async (stageId: string, name: string) => {
        try {
            handleConfirmationAlert({
                title: "¿Estás seguro de generar el Ingreso?",
                html: (
                    <>
                        {/* Hay que usar referencias porque al ser html nativo no re-renderiza el componente y por lo tanto no cambia el estado */}
                        <div className="d-flex justify-content-center">
                            <FormGroup label="Fecha:" className="col-md-6 mb-2" requiredInputLabel>
                                <input
                                    type="date"
                                    name="date"
                                    ref={dateRef}
                                    defaultValue={moment().format('YYYY-MM-DD')}
                                    className="form-control"
                                />
                            </FormGroup>
                        </div>
                        <FormGroup label="Concepto:" className="mb-2" requiredInputLabel>
                            <input type="text" ref={conceptRef} className="form-control" defaultValue={name} />
                        </FormGroup>
                        <FormGroup label="Comentario:">
                            <textarea ref={commentRef} className="form-control" rows={5}></textarea>
                        </FormGroup>
                    </>
                ),
                icon: "warning",
                onConfirm: () => {
                    let dateValue = dateRef.current?.value || '';
                    let conceptValue = conceptRef.current?.value || '';
                    let commentValue = commentRef.current?.value || '';

                    if (!dateValue || dateValue === '') {
                        toast.error('La fecha es obligatoria');
                    }
                    if (!conceptValue || conceptValue === '') {
                        toast.error('El concepto es obligatorio');
                    }

                    if (dateValue !== '' && conceptValue !== '') {
                        setTimeout(async () => {
                            const response = (await (new BudgetService).toggleStageValidate(stageId, conceptValue, commentValue, dateValue)).getResponseData();
                            if (response.success) {
                                toast.success('Ingreso creado correctamente');
                                refetchBudget();
                            } else {
                                toast.error(response.message);
                            }
                        }, 100);
                    }
                },
            });

        } catch (error: any) {
            toast.error(error.message || 'Error al validar la fase');
        }
    };

    const generateBudgetPDF = async () => {
        try {
            const response = (await (new BudgetService).generateBudgetPDF(id));
            if (response) {
                const pdfName = `${budget.codeIdentifier.replace(/-/g, '_')} ${slugify(budget.address, { remove: /[.]/g })}.pdf`;
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', pdfName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar el presupuesto');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al descargar el presupuesto');
        }
    };

    const _handleUploadImage = async (e: React.ChangeEvent<any>, type: string) => {
        const maxSizeBytes = 350000; // 350 kB
        const selectedFile = e.target.files && e.target.files[0];

        if (selectedFile && selectedFile.size > maxSizeBytes) {
            toast.error('El tamaño máximo de la imagen es de 350 kB');
            return;
        } else {
            try {
                const response = (await (budgetService.uploadImage(id, selectedFile, type))).getResponseData();
                if (response) {
                    refetchBudget();
                    toast.success('Imagen actualizada correctamente');
                } else {
                    response.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message || 'Error al subir la imagen');
            }
        }
    };

    const _handleSaveExtraInfo = async (values: IFormExtraInfo) => {
        values.budget = budget.id;
        try {
            const response = (await budgetService.saveExtraInfo(values)).getResponseData();
            if (response.success) {
                refetchBudget();
                toast.success('Información actualizada correctamente');
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Función para formatear el número de semanas
    function formatWeeks(numOfWeeks: number): string {
        if (numOfWeeks === 1) {
            return '1 semana';
        } else if (numOfWeeks < 4) {
            return `${numOfWeeks} semanas`;
        } else if (numOfWeeks === 4) {
            return '1 mes';
        } else if (numOfWeeks < 52) {
            const numMonths = Math.floor(numOfWeeks / 4);
            const numRemainingWeeks = numOfWeeks % 4;
            const monthsPart = numMonths > 1 ? `${numMonths} meses` : '1 mes';
            const weeksPart = numRemainingWeeks > 0 ? ` ${numRemainingWeeks} semanas` : '';
            return `${monthsPart}${weeksPart}`;
        } else {
            const numYears = Math.floor(numOfWeeks / 52);
            const numRemainingWeeks = numOfWeeks % 52;
            const yearsPart = numYears > 1 ? `${numYears} años` : '1 año';
            const weeksPart = numRemainingWeeks > 0 ? ` ${numRemainingWeeks} semanas` : '';
            return `${yearsPart}${weeksPart}`;
        }
    };

    function extractTextInParentheses(inputString: string): string | null {
        if (inputString === 'Otros') {
            return inputString;
        }

        const regex = /\(([^)]+)\)/; // Expresión regular para buscar texto entre paréntesis
        const matches = inputString.match(regex);

        if (matches && matches.length > 0) {
            return matches[0];
        } else {
            return null;
        }
    };

    const formik = useFormik<IFormExtraInfo>({
        initialValues: {
            budget: id || '',
            pageText: budget?.pageText
        },
        onSubmit: values => _handleSaveExtraInfo(values),
    });

    useEffect(() => {
        if (budget) {
            formik.setFieldValue('pageText', budget.pageText);
        }
    }, [budget]);

    useEffect(() => {
        fetchStatuses();
    }, []);

    if (loadingBudget) return <div className="text-center"><Spinner /></div>;

    if (budgetError) return <ErrorMessage message={budgetError} />;

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color="secondary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                    <CardTitle>Presupuesto: {budget.name}<span className="ms-3">{budget.codeIdentifier}</span></CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight className="d-flex align-items-center">
                    <label className="labelStyle me-2 mb-0">Estado:</label>
                    <StatusesDropdown
                        onChange={() => { refetchBudget(); refetchChanges(); }}
                        key={budget.id + "-status"}
                        itemId={budget.id}
                        statuses={budgetStatuses}
                        status={
                            budget.budgetStatus != null
                                ? budget.budgetStatus
                                : null
                        }
                        additionalInfo={
                            budget.budgetStatusDate
                                ? moment(budget.budgetStatusDate?.date).format("DD/MM/yyyy HH:mm")
                                : undefined
                        }
                        orientedTo={"budget"}
                    />
                    <Button
                        color='dark'
                        title="Descargar el presupuesto"
                        className="ms-4 me-2"
                        onClick={() => generateBudgetPDF()}
                    >
                        <IconDownload size={20} />
                    </Button>
                    <Button
                        color='dark'
                        title="Enviar presupuesto al cliente"
                        onClick={() => {
                            setSpinnerMail(true);
                            sendMailToClient();
                        }}
                    >
                        {spinnerMail ? <Spinner size={18} /> : <IconBrandGmail size={20} />}
                    </Button>
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <input type="file" ref={fileInputRef1} style={{ display: 'none' }} onChange={(e) => { _handleUploadImage(e, 'cover') }} />
                <input type="file" ref={fileInputRef2} style={{ display: 'none' }} onChange={(e) => { _handleUploadImage(e, 'offer') }} />

                <Card>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-6 d-flex flex-column">
                                {budget.project && <span className="fs-5"><span className="labelStyle">Proyecto: <a href={`${FinancesMenu.project.path}/${budget.project.id}/vista`}>{budget.project.codeIdentifier}</a></span></span>}

                                <span className="fs-5">
                                    <span className="labelStyle">Cliente: </span>
                                    {userCan('get', 'clients')
                                        ? (
                                            <a href={`${ClientMenu.clients.path}/${budget.client?.id}/perfil`} className="cursor-pointer">
                                                {budget.client?.name + (budget.client?.firstName ? (' ' + budget.client?.firstName) : '') + (budget.client?.lastName ? (' ' + budget.client?.lastName) : '')}
                                            </a>
                                        )
                                        : budget.client?.name + (budget.client?.firstName ? (' ' + budget.client?.firstName) : '') + (budget.client?.lastName ? (' ' + budget.client?.lastName) : '')
                                    }
                                </span>

                                <span className="fs-5"><span className="labelStyle">Promotor: </span>{budget.sponsor !== null ? budget.sponsor?.name + (budget.sponsor?.firstName ? (' ' + budget.sponsor?.firstName) : '') + (budget.sponsor?.lastName ? (' ' + budget.sponsor?.lastName) : '') : 'No proporcionado'}</span>

                                <span className="fs-5"><span className="labelStyle">Dirección: </span>{budget.address || <span className="text-muted">No proporcionada</span>}</span>
                            </div>

                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center flex-column">
                                        <div className="flex-row">
                                            <span className="labelStyle">Imagen de portada</span>
                                            <Button
                                                color="dark"
                                                isLight
                                                title="Subir imagen"
                                                icon="CloudUpload"
                                                onClick={() => fileInputRef1.current?.click()}
                                                className="ms-2"
                                            />
                                        </div>
                                        {budget.coverImg
                                            ? <AsyncImg id={budget.coverImg?.id} height="100" width="150" />
                                            : <span className="labelStyle">No disponible</span>
                                        }
                                    </div>

                                    <div className="col-md-6 d-flex align-items-center flex-column">
                                        <div className="flex-row">
                                            <span className="labelStyle">Imagen de oferta</span>
                                            <Button
                                                color="dark"
                                                isLight
                                                title="Subir imagen"
                                                icon="CloudUpload"
                                                onClick={() => fileInputRef2.current?.click()}
                                                className="ms-2"
                                            />
                                        </div>
                                        {budget.offerImg
                                            ? <AsyncImg id={budget.offerImg?.id} height="100" width="150" />
                                            : <span className="labelStyle">No disponible</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <CardTitle tag="h4" className="h5">Conceptos</CardTitle>
                            {budget.concept?.length > 0
                                ? (
                                    <>
                                        <div className="row mt-2 mb-1">
                                            <span className="col-md-1 labelStyle">Cantidad</span>
                                            <span className="col-md-2 labelStyle">Nombre</span>
                                            <span className="col-md-4 labelStyle">Descripción</span>
                                            <span className="labelStyle" style={{ width: '10%' }}>Precio</span>
                                            <span className="labelStyle" style={{ width: '10%' }}>Impuesto</span>
                                            <span className="labelStyle" style={{ width: '10%' }}>Retención</span>
                                            <span className="labelStyle" style={{ width: '10%', fontWeight: 'bold' }}>Total</span>
                                        </div>

                                        {budget.concept.map((concept: Concept) => {
                                            return (
                                                <div className="row" key={concept.id}>
                                                    <span className="col-md-1">{concept.quantity}</span>
                                                    <span className="col-md-2">{extractTextInParentheses(concept.name)}</span>
                                                    <span className="col-md-4">{concept.textField}</span>
                                                    <span style={{ width: '10%' }}>{concept.amount.toFixed(2)} €</span>
                                                    <span style={{ width: '10%' }}>{concept.tax} %</span>
                                                    <span style={{ width: '10%' }}>{budget.withholdings} %</span>
                                                    <span style={{ width: '10%', fontWeight: 'bold' }}>{concept.priceTotal.toFixed(2)} €</span>
                                                </div>
                                            )
                                        })
                                        }
                                    </>
                                )
                                : <span className="text-muted">No tiene conceptos añadidos</span>
                            }
                        </div>

                        <div className="row mt-5 mb-5">
                            <CardTitle tag="h4" className="h5">Fases del pago</CardTitle>
                            {budget.stages?.length > 0
                                ? (
                                    <>
                                        <div className="row mt-2 mb-1">
                                            <FormLabel label="Nombre" className="col-md-4" />
                                            <FormLabel label="Descripción" className="col-md-3" />
                                            <FormLabel label="Duración" className="col-md-1" />
                                            <FormLabel label="Enviado" className="col-md-1 text-center" />
                                            <>
                                                <span className="col-md-1 labelStyle text-center">Factura</span>
                                                {spinner && (
                                                    <span className="col-md-1 d-flex align-items-center">
                                                        <Spinner size={20} />
                                                    </span>
                                                )}
                                            </>
                                            <FormLabel label="Validar" className="col-md-1 text-end" />
                                        </div>
                                        {budget.stages?.map((stage: any) => {
                                            return (
                                                <div className="row" key={stage.id}>
                                                    <span className="col-md-4">
                                                        <b>{stage.name}</b> - {
                                                            stage.stageHasConcept.length > 0
                                                                ? (stage.stageHasConcept.map((concept: any) => {
                                                                    return (
                                                                        <span key={concept.id} className="me-3">{extractTextInParentheses(concept.concepts.name)} ({concept.percentage}%) </span>
                                                                    )
                                                                }))
                                                                : <span className="text-muted">Sin conceptos</span>
                                                        }
                                                    </span>
                                                    <span className="col-md-3">{stage.textField || <span className="text-muted">Sin descripción</span>}</span>
                                                    <span className="col-md-1">{formatWeeks(stage.duration)}</span>
                                                    <span className="col-md-1 d-flex justify-content-center p-0">
                                                        <Checks
                                                            id={stage.id}
                                                            checked={stage.isSended}
                                                            onChange={() => { }}
                                                            className="form-control p-0"
                                                            disabled
                                                        />
                                                    </span>
                                                    {budget.budgetStatus?.finalStatus
                                                        ? (
                                                            <span className="col-md-1 d-flex justify-content-around pb-2">
                                                                <Button
                                                                    color='dark'
                                                                    icon={stage.invoices.length > 0 ? "'SimCardDownload'" : "'Receipt'"}
                                                                    title={stage.invoices.length > 0 ? "Descargar factura de la fase" : "Generar factura"}
                                                                    className="me-2"
                                                                    onClick={() => { checkInvoiceData(() => downloadInvoice(stage.invoices, stage.id, stage.invoices[0]?.invoiceIdentifier)) }}
                                                                />

                                                                <Button
                                                                    color='dark'
                                                                    title="Enviar al cliente"
                                                                    icon="Mail"
                                                                    className={classNames('me-2', { 'd-none': stage.invoices.length === 0 })}
                                                                    onClick={() => {
                                                                        setSpinner(true);
                                                                        sendMailToClient(stage.id)
                                                                    }}
                                                                />
                                                            </span>
                                                        )
                                                        : <span className="col-md-1"></span>
                                                    }
                                                    <span className="col-md-1 d-flex justify-content-end">
                                                        {stage.isValidated
                                                            ? <Icon icon="Check" size={'lg'} color="green" />
                                                            : (
                                                                <Button
                                                                    color='secondary'
                                                                    isLight
                                                                    title="Generar Ingreso"
                                                                    icon="CreditScore"
                                                                    onClick={() => { checkInvoiceData(() => toggleStageValidate(stage.id, stage.name)) }}
                                                                    className="p-0"
                                                                />
                                                            )
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </>
                                )
                                : <span className="text-muted">No tiene fases añadidas</span>
                            }
                        </div>

                        <StatusesTimeLine statusChanges={statusChanges} />
                    </CardBody>
                </Card>
            </Page>
        </>
    )
}

export default BudgetView;